.sidebar-default {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease, opacity 1s ease;
  transform: translateX(-100%);
  opacity: 0;
  z-index: 10;
  top: 0;
}

.sidebar {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.3s ease, opacity 1s ease;
}

.sidebar-open {
  transform: translateX(0);
  opacity: 1;
  transition: transform 0.3s ease, opacity 1s ease;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
}
