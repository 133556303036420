/* loader.css */
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vh;
    height: 100vh;
    /* background-color: rgba(0, 0, 0, 0.2); */
    border-radius: 50%;
    animation: loaderAnimation 2s infinite linear;
    opacity: 0;
    transform: scale(0.2) rotate(0deg);
  }
  
  @keyframes loaderAnimation {
    0% {
      opacity: 0;
      transform: scale(0.2) rotate(0deg);
    }
    100% {
      opacity: 1;
      transform: scale(1) rotate(360deg);
    }
  }