@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:wght@100..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
html * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DM Sans";
}

body {
  background-color: #f4f7fe;
}

input {
  /* font-family: "Poppins", sans-serif; */
}

a {
  color: black;
  font-weight: 600;
  text-decoration: none;
  margin: 1px;
}

li {
  list-style: none;
}

:root {
  --primaryColor1: #1e6de8;
  --secondaryColor: #294169;
  --secondaryColor2: #162f57;
  --whiteColor: #fff;
  --darkColor1: #2a2a2a;
  --lightDarkColor: #4e5566;
  --backgroundColor1: #f4f7fe;
}

/* removing background from antd message component */
.success-message .ant-message-notice-content {
  background-color: #3fb34f !important;
}

/* disabled antd menu options */
.ant-menu-item-disabled {
  color: rgba(0, 0, 0, 0.7) !important;
}

.ant-drawer-content-wrapper {
  height: 95vh !important;
}

.animate-bounce {
  animation: bounce 0.3s linear;
}
@keyframes bounce {
  /* 0% {
    margin-bottom: 0px;
  } */
  0% {
    margin-bottom: 50px;
  }
  80% {
    margin-bottom: -10px;
  }
  100% {
    margin-bottom: 0px;
  }
}

.shaded-background {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 50;
  height: 100vh;
  width: 100%;
  background: rgb(0, 0, 0, 0.6);
}

* {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.viewFullscreen:hover {
  animation: ping 0.5s cubic-bezier(0, 0, 0.2, 1);
}
.exitFullscreen:hover {
  animation: shrink 0.5s cubic-bezier(0, 0, 0.2, 1);
}

@keyframes ping {
  25% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1.5);
  }
  75% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes shrink {
  25% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1.5);
  }
  75% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}


.volumeSeek {
  animation: volumeSeekAnimation 0.5s cubic-bezier(0, 0, 0.2, 1);
}

@keyframes volumeSeekAnimation {
  25%,
  100% {
    width: 96px;
  }
}

.dropdown:focus-within .dropdown-menu {
  opacity: 1;
  transform: translate(0) scale(1);
  visibility: visible;
}

.ant-modal-close {
  border-radius: 50% !important;
}

/* custom-scrollbar */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #727272;
}

.player video {
  border-radius: 6px;
}


.fastforward {
  display: flex;
}

.fastforward .arrow {

  background: #f0f0f0;
  margin: 0 2px;
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
  opacity: 0;
  animation: fadeInOut 2s ease-in-out infinite;
}

.fastforward .arrow:nth-child(2) {
  animation-delay: 0.2s;
}

.fastforward .arrow:nth-child(3) {
  animation-delay: 0.3s;
}
@keyframes fadeInOut {
  0%, 40%, 100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}
.rewindBackward {
  display: flex;
  align-items: center; /* Center align arrows vertically */
}

.rewindBackward .arrow {
  background: #f0f0f0;
  margin: 0 2px;
  clip-path: polygon(0% 50%, 100% 0%, 100% 100%);
  opacity: 0;
  animation: fadeInOut 2s ease-in-out infinite;
}

.rewindBackward .arrow:nth-child(1) {
  animation-delay: 0.2s;
}
.rewindBackward .arrow:nth-child(2) {
  animation-delay: 0.2s;
}

.rewindBackward .arrow:nth-child(3) {
  animation-delay: 0.1s; 
}

@keyframes fadeInOut {
  0%, 40%, 100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}
