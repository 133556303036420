.main {
  /* width: 100%; */
  /* min-height: 100vh; */
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 19.773px;
  border: 1px solid #e0e0e0;
  background: var(--Color-White-100, #fff);
  box-shadow: 0px 0px 11.3px 0px rgba(8, 89, 222, 0.15);
}

.inner-box {
  position: absolute;
  height: 100%;
  width: 100%;
}

.form-wrap {
  position: absolute;
  width: 50%;
  min-height: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  transition: 0.8s ease-in-out;
  border-radius: 24px;
}

.carousel {
  position: absolute;
  width: 50%;
  top: -10px;
  transition: 0.8s ease-in-out;
  left: 50%;
  min-height: 100%;
}

.sign-up-form {
  opacity: 0;
  pointer-events: none;
}

.main.sign-up-mode .sign-in-form {
  opacity: 0;
  pointer-events: none;
}
.main.sign-up-mode .sign-up-form {
  opacity: 1;
  pointer-events: all;
}
.main.sign-up-mode .form-wrap {
  left: 50%;
}

.main.sign-up-mode .carousel {
  left: 0%;
}
